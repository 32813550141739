import Cookies from 'js-cookie';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import CONFIG_API from "../../config.json";
import axios from "axios";

const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr } = actions;

const login = (values, callback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      console.log('login $$$$$$$$', values)
      const response = await axios.post(CONFIG_API['RASPORTS.API'] + "validateAuth", values, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.errors) {
        Cookies.remove('user_id');
        Cookies.remove('secret_key');
        Cookies.remove('user_role');
        dispatch(loginErr(response.data.errors));
      } else {
        Cookies.set('access_token', '12-SL');
        Cookies.set('logedIn', true);
        Cookies.set('user_id', response.data.id);
        Cookies.set('secret_key', response.data.secretKey);
        Cookies.set('user_role', response.data.role);
        dispatch(loginSuccess(true));
        callback();
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const fbLogin = (callback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      Cookies.set('logedIn', true);
      dispatch(loginSuccess(true));
      callback();
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const register = (values) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.post('/register', values);
      if (response.data.errors) {
        dispatch(loginErr('Registration failed!'));
      } else {
        dispatch(loginSuccess(false));
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const logOut = (callback) => {
  return async (dispatch) => {
    dispatch(logoutBegin());
    try {
      Cookies.remove('logedIn');
      Cookies.remove('access_token');
      dispatch(logoutSuccess(false));
      callback();
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { login, logOut, register, fbLogin };
