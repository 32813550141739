import {
  Uil500px,
  UilAirplay,
  UilArrowGrowth,
  UilAt,
  UilBagAlt,
  UilBookAlt,
  UilBookOpen,
  UilBookReader,
  UilCalendarAlt,
  UilChartBar,
  UilChat,
  UilCheckSquare,
  UilCircle,
  UilClipboardAlt,
  UilClock,
  UilCompactDisc,
  UilCreateDashboard,
  UilDatabase,
  UilDocumentLayoutLeft,
  UilEdit,
  UilEnvelope,
  UilExchange,
  UilExclamationOctagon,
  // UilExpandArrowsAlt,
  UilFile,
  UilFileShieldAlt,
  UilHeadphones,
  UilIcons,
  UilImages,
  UilLayerGroup,
  UilMap,
  UilPresentation,
  UilQuestionCircle,
  UilSearch,
  UilServer,
  UilSetting,
  UilShoppingCart,
  UilSquareFull,
  UilTable,
  UilUsdCircle,
  UilUsersAlt,
  UilWindowSection,
} from '@iconscout/react-unicons';
import { Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';
import { NavTitle } from './Style';
import versions from '../demoData/changelog.json';
import { changeDirectionMode, changeLayoutMode, changeMenuMode } from '../redux/themeLayout/actionCreator';
import UilPaperclip from '@iconscout/react-unicons/icons/uil-paperclip';

function MenuItems({ toggleCollapsed }) {
  const { t } = useTranslation();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  const dispatch = useDispatch();

  const path = '/admin';

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const changeLayout = (mode) => {
    dispatch(changeLayoutMode(mode));
  };
  const changeNavbar = (topMode) => {
    const html = document.querySelector('html');
    if (topMode) {
      html.classList.add('ninjadash-topmenu');
    } else {
      html.classList.remove('ninjadash-topmenu');
    }
    dispatch(changeMenuMode(topMode));
  };
  const changeLayoutDirection = (rtlMode) => {
    if (rtlMode) {
      const html = document.querySelector('html');
      html.setAttribute('dir', 'rtl');
    } else {
      const html = document.querySelector('html');
      html.setAttribute('dir', 'ltr');
    }
    dispatch(changeDirectionMode(rtlMode));
  };

  const darkmodeActivated = () => {
    document.body.classList.add('dark-mode');
  };

  const darkmodeDiactivated = () => {
    document.body.classList.remove('dark-mode');
  };

  const items = [
    getItem(t('dashboard'), 'dashboard', !topMenu && <UilCreateDashboard />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/demo-3`}>
          {t('finance')} {t('report')}
        </NavLink>,
        'demo-3',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/demo-4`}>
          {t('Activity')} {t('report')}
        </NavLink>,
        'demo-4',
        null,
      ),
    ]),
    getItem(
      !topMenu && <NavTitle className="ninjadash-sidebar-nav-title">{t('My Schedule')}</NavTitle>,
      'my-schedule',
      null,
      null,
      'group',
    ),
    getItem(t('Tasks'), 'supports', !topMenu && <UilSetting />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/app/calendar/month`}>
          {t('calendar')}
        </NavLink>,
        't-menu-calendar',
        null,
      ),
    ]),
    getItem(
      !topMenu && <NavTitle className="ninjadash-sidebar-nav-title">{t('Business')}</NavTitle>,
      'team-management',
      null,
      null,
      'group',
    ),
    getItem(t('Client'), 'clients', !topMenu && <UilPaperclip />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/users/client/grid-style`}>
          {t('All Clients')}
        </NavLink>,
        'client-grid',
        null,
      ),
    ]),
    getItem(
      !topMenu && <NavTitle className="ninjadash-sidebar-nav-title">{t('Roles')}</NavTitle>,
      'roles',
      null,
      null,
      'group',
    ),
    getItem(t('Manager'), 'managers', !topMenu && <UilUsersAlt />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/users/manager/grid-style`}>
          {t('All Managers')}
        </NavLink>,
        'manager-grid',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/users/add-manager/info`}>
          {t('add')} {t('manager')}
        </NavLink>,
        'addManager',
        null,
      ),
    ]),
    getItem(t('Supervisor'), 'supervisors', !topMenu && <UilUsersAlt />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/users/supervisor/grid-style`}>
          {t('All Supervisors')}
        </NavLink>,
        'supervisor-grid',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/users/add-supervisor/info`}>
          {t('add')} {t('supervisor')}
        </NavLink>,
        'addSupervisor',
        null,
      ),
    ]),
    getItem(t('Cleaner'), 'users', !topMenu && <UilUsersAlt />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/users/cleaner/grid-style`}>
          {t('All Cleaners')}
        </NavLink>,
        'user-grid',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/users/add-cleaner/info`}>
          {t('add')} {t('cleaner')}
        </NavLink>,
        'addCleaner',
        null,
      ),
    ]),
    getItem(
      !topMenu && <NavTitle className="ninjadash-sidebar-nav-title">{t('Supplies')}</NavTitle>,
      'supplies',
      null,
      null,
      'group',
    ),
    getItem(t('Supplier'), 'suppliers', !topMenu && <UilUsersAlt />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/users/supplier/grid-style`}>
          {t('All Suppliers')}
        </NavLink>,
        'supplier-grid',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/users/add-supplier/info`}>
          {t('add')} {t('supplier')}
        </NavLink>,
        'addSupplier',
        null,
      ),
    ]),
    getItem(
      !topMenu && <NavTitle className="ninjadash-sidebar-nav-title">{t('Templates')}</NavTitle>,
      'templates',
      null,
      null,
      'group',
    ),
    getItem(t('Template'), 'templates', !topMenu && <UilFile />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/app/note-checklist/all`}>
          {t('Checklist templates')}
        </NavLink>,
        'addEditChecklist',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/app/note-inspection/all`}>
          {t('Inspection templates')}
        </NavLink>,
        'addEditInspection',
        null,
      ),
    ]),
    getItem(
      !topMenu && <NavTitle className="ninjadash-sidebar-nav-title">{t('application')}</NavTitle>,
      'application',
      null,
      null,
      'group',
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/main/chat/private/rofiq@gmail.com`}>
        {t('chat')}
      </NavLink>,
      'chat',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/main/chat/private/rofiq@gmail.com`}>
          <UilChat />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/maps/google`}>
        {t('map')}
      </NavLink>,
      'map',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/maps/google`}>
          <UilMap />
        </NavLink>
      ),
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/pages/settings`}>
        {t('settings')}
      </NavLink>,
      'settings',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/pages/settings`}>
          <UilSetting />
        </NavLink>
      ),
    ),
  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
            `${mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
            }`,
          ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
