import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const Users = lazy(() => import('../../container/users/Users'));
const AddUser = lazy(() => import('../../container/users/AddUsers'));
const EditUser = lazy(() => import('../../container/users/EditUsers'));
const UserDetails = lazy(() => import('../../container/users/UserDetails'));
const DataTable = lazy(() => import('../../container/users/UserListDataTable'));
const Team = lazy(() => import('../../container/users/Team'));
const NotFound = lazy(() => import('../../container/pages/404'));

function PagesRoute() {
  return (
    <Routes>
      <Route path="client/*" element={<Users roleType="Client" />} />
      <Route path="add-client/*" element={<AddUser roleType="Client"/>} />
      <Route path="edit-client/*" element={<EditUser roleType="Client"/>} />
      <Route path="clientDetails/:id" element={<UserDetails roleType="Client"/>} />
      <Route path="client/dataTable" element={<DataTable roleType="Client"/>} />

      <Route path="manager/*" element={<Users roleType="Manager" />} />
      <Route path="add-manager/*" element={<AddUser roleType="Manager"/>} />
      <Route path="edit-manager/*" element={<EditUser roleType="Manager"/>} />
      <Route path="managerDetails/:id" element={<UserDetails roleType="Manager"/>} />
      <Route path="manager/dataTable" element={<DataTable roleType="Manager"/>} />

      <Route path="supervisor/*" element={<Users roleType="Supervisor" />} />
      <Route path="add-supervisor/*" element={<AddUser roleType="Supervisor"/>} />
      <Route path="edit-supervisor/*" element={<EditUser roleType="Supervisor"/>} />
      <Route path="supervisorDetails/:id" element={<UserDetails roleType="Supervisor"/>} />
      <Route path="supervisor/dataTable" element={<DataTable roleType="Supervisor"/>} />

      <Route path="cleaner/*" element={<Users roleType="Cleaner" />} />
      <Route path="add-cleaner/*" element={<AddUser roleType="Cleaner"/>} />
      <Route path="edit-cleaner/*" element={<EditUser roleType="Cleaner"/>} />
      <Route path="cleanerDetails/:id" element={<UserDetails roleType="Cleaner"/>} />
      <Route path="cleaner/dataTable" element={<DataTable roleType="Cleaner"/>} />

      <Route path="supplier/*" element={<Users roleType="Supplier" />} />
      <Route path="add-supplier/*" element={<AddUser roleType="Supplier"/>} />
      <Route path="edit-supplier/*" element={<EditUser roleType="Supplier"/>} />
      <Route path="supplierDetails/:id" element={<UserDetails roleType="Supplier"/>} />
      <Route path="supplier/dataTable" element={<DataTable roleType="Supplier"/>} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default PagesRoute;
